import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HomePage } from '../pages';
import {  SmFooter } from '../components';
import { Header } from '../components/Header';

const Router = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
      <SmFooter />
    </BrowserRouter>
  )
}

export default Router