import React from 'react'
import { Row, Col, Image } from 'antd'
import './index.css'

const ImageGallery = () => {
  return (
    <div className='justify-center  mt-5'>
        <div className='width-93'>
            <Row gutter={[24,24]}>
                <Col lg={{span: 6}} md={{span: 6}} xs={{span: 24}} sm={{span: 24}}>
                    <div className="img1">
                        <Image src="/assets/images/img-1.webp" alt="" />
                    </div>
                    <div className="img1">
                        <Image src="/assets/images/img-5.jpg" alt="" />
                    </div>
                </Col>
                <Col lg={{span: 12}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                    <Row gutter={[24,24]}>
                        <Col lg={{span: 12}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                            <div className="img2">
                                <Image src="/assets/images/img-2.avif" alt="" />
                            </div>
                        </Col>
                        <Col lg={{span: 12}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                            <div className="img2">
                                <Image src="/assets/images/img-3.jpg" alt="" />
                            </div>
                        </Col>
                        <Col lg={{span: 12}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                            <div className="img2">
                                <Image src="/assets/images/img-4.jpg" alt="" />
                            </div>
                        </Col>
                        <Col lg={{span: 12}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                            <div className="img2">
                                <Image src="/assets/images/img-5.jpg" alt="" />
                            </div>
                        </Col>
                        <Col lg={{span: 12}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                            <div className="img2">
                                <Image src="/assets/images/img-6.jpg" alt="" />
                            </div>
                        </Col>
                        <Col lg={{span: 12}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                            <div className="img2">
                                <Image src="/assets/images/img-7.jpg" alt="" />
                            </div>
                        </Col>
                        <Col lg={{span: 12}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                            <div className="img2">
                                <Image src="/assets/images/img-8.jpg" alt="" />
                            </div>
                        </Col>
                        <Col lg={{span: 12}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                            <div className="img2">
                                <Image src="/assets/images/img-9.jpg" alt="" />
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col lg={{span: 6}} md={{span: 6}} xs={{span: 24}} sm={{span: 24}}>
                    <div className="img1">
                        <Image src="/assets/images/img-10.jpg" alt="" />
                    </div>
                    <div className="img1">
                        <Image src="/assets/images/img-11.jpg" alt="" />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {ImageGallery}