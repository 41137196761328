import React from 'react'
import { Button, Drawer, Image, Space } from 'antd'
import {
    LogoutOutlined,
    ArrowRightOutlined
} from '@ant-design/icons'
import { Link } from 'react-router-dom'
import './index.css'
import { CurvedButton } from '../Buttons'

const Mobilemenu = ({visible,onClose}) => {

  return (
    <div>
        <Drawer closeIcon={false} open={visible}>
            <Space style={{justifyContent:"space-between",width:"100%"}} align='center'>
                <Image
                    src='/assets/images/Health Flow Pro.png'
                    alt='Health flow pro' width={'60px'} preview={false}
                />
                <Button className='btn-menu' onClick={onClose}>
                    <LogoutOutlined style={{fontSize:"30px"}} className='brandcolor' />
                </Button>
            </Space>
            <Space direction='vertical' style={{width:'100%'}}>
                <ul className='ul-menu'>
                    <li>
                        <Link to={'/'}>
                            Product
                            <ArrowRightOutlined style={{fontSize:"14px"}} />
                        </Link>
                    </li>
                    <li>
                        <Link to={'/'}>
                            Contact
                            <ArrowRightOutlined style={{fontSize:"14px"}} />
                        </Link>
                    </li>
                    <li>
                        <Link to={'/'}>
                            Clients
                            <ArrowRightOutlined style={{fontSize:"14px"}} />
                        </Link>
                    </li>
                    <li>
                        <Link to={'/'}>
                            Partners
                            <ArrowRightOutlined style={{fontSize:"14px"}} />
                        </Link>
                    </li>
                </ul>
                <div className='right-side'>
                    <CurvedButton title='Book a demo' to='/'/>
                </div>
            </Space>
        </Drawer>
    </div>
  )
}

export {Mobilemenu}