import React, { useState } from 'react'
import { Space, Typography, Image, Button } from 'antd'
import "./index.css"
import { MenuOutlined } from '@ant-design/icons'
import { Mobilemenu } from '../Mobilemenu'
const { Text } = Typography


const Header = () => {

    const [visible, setVisible] = useState(false)

    const onClose = () => {
        setVisible(false)
    }

    const data = [
        {
            iconimg:'call-f.png',
            title:'Location',
            text: 'Rawalpindi, Punjab 46000, Pakistan'
        },
        {
            iconimg:'location-f.png',
            title:'Call us now',
            text: '+92 336 3334161'
        },
        {
            iconimg:'clock-f.png',
            title:'Time',
            text: 'Mon - Sun (24/7)'
        },
        {
            iconimg:'mail-f.png',
            title:'Mail',
            text: 'contact@healthflowpro.com'
        },
    ]
  return (
    <div className='flex-col-center py-5 width-100'>
        <div className='width-93 space-between-1'> 
            <div style={{width:'110px', padding:'8px 0'}} className='mbl-menu'>
                <Image
                    src='/assets/images/Patient flow hub LOGO.png'
                    alt='Health flow pro' className='logo' preview={false}
                />
                <Button className='btn-menu' onClick={()=>setVisible(true)}>
                    <Image
                        src='/assets/icons/menu-bar.png'
                        alt='hamburger icon' width={'40px'} preview={false}
                    />
                </Button>
            </div>
            <div className='topinfobar'>
                {
                    data?.map((dat, index)=>
                    <div 
                        key={'header-'+index} 
                        className='justify-end'
                        style={{marginLeft: '32px'}}
                        >
                        <Space size={20} className='mbl-space'>
                            <Image 
                                src={'/assets/icons/'+ dat?.iconimg} 
                                preview={false}  
                                alt='epic-ride-london'
                                />
                            <Space direction='vertical' size={4}>
                                <Text className='text-grey'>
                                    {
                                        dat?.title
                                    }
                                </Text>
                                <Text 
                                    strong 
                                    className='my-0'
                                    ellipsis={{rows:1}}
                                >
                                    {
                                        dat?.text
                                    }
                                </Text>
                            </Space>
                        </Space>
                    </div>
                    )
                }
            </div>
        </div>
        <div className='width-93' style={{position: 'relative'}}>
            <div className='d-flex mini-header-navbar-cover '>
            <div className='skew-box'>
                <div></div>
            </div>
                <div className='mini-header-navbar'>
                    <div className='left-side'>
                        <div>
                            <span>Product</span>
                            <span className='animated-bottom-border animation'></span>
                        </div>
                        <div>
                            <span>Contact</span>
                            <span className='animated-bottom-border animation'></span>
                        </div>
                        <div>
                            <span>Clients</span>
                            <span className='animated-bottom-border animation'></span>
                        </div>
                        <div>
                            <span>Partners</span>
                            <span className='animated-bottom-border animation'></span>
                        </div>
                    </div>
                    <div className='right-side'>
                        <div>
                            <MenuOutlined />
                        </div>
                        <div>
                            Book a demo
                        </div>
                        <div>
                            Book a demo
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Mobilemenu visible={visible} onClose={onClose} onClick={()=>setVisible(false)} />
    </div>
  )
}

export {Header}