import React from 'react'
import { Col, Row, Typography, Dropdown, Space, Image, Button } from 'antd'
import { DownOutlined } from '@ant-design/icons';

const SmFooter = () => {
    const items = [
        {
          key: '1',
          label: (
            <a target="_blank" href="">
              <Space>
                <Image src='assets/eng.png' width={'22px'} />
                <Typography.Text>English</Typography.Text>
              </Space>
            </a>
          ),
        },
        {
          key: '2',
          label: (
            <a target="_blank" href="">
              <Space>
                <Image src='assets/eng.png' width={'22px'} />
                <Typography.Text>Arabic</Typography.Text>
              </Space>
            </a>
          ),
        },
        {
            key: '3',
            label: (
              <a target="_blank" href="">
                <Space>
                  <Image src='assets/eng.png' width={'22px'} />
                  <Typography.Text>German</Typography.Text>
                </Space>
              </a>
            ),
          },
      ];
  return (
    <div className='secondary-bg justify-center py-px-3'>
        <div className='width-93'>
            <Row gutter={[24,24]} align={'middle'}>
                <Col lg={{span:12}} md={{span:12}} xs={{span: 24}} sm={{span:24}}>
                    <Typography.Title className='m-0 text-white' level={5}>
                        Developed by Repla Security & Technology
                    </Typography.Title>
                </Col>
                <Col lg={{span:12}} md={{span:12}} xs={{span: 24}} sm={{span:24}}>
                    <Space className='width-100' style={{justifyContent:'end'}}>
                        <Dropdown
                            menu={{
                            items,
                            }}
                            trigger={['click']}
                        >
                            <Button onClick={(e) => e.preventDefault()}>
                                <Space align='center'>
                                    <Image src='assets/eng.png' width={'22px'} />
                                    <Typography.Text>English</Typography.Text>
                                    <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    </Space>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {SmFooter}