import React from 'react'
import { Col, Row, Space, Typography } from 'antd';
import { ErpButton } from '../ErpButton';
import './index.css';

const MainBanner = () => {

    const data = 
        {
            title1:"The #1 Software for HPI- ERP",
            title2:"Simple, flexible and powerful booking software for your business. And it's subscription-free!",
            desc:"Growing Vision gives you daily tools, tactics and techniques to banish procrastination, boost your confidence and excel — in every area of your life. Our Hair Transplant Center offers comprehensive treatment options for men and women struggling with hair loss. We strive to stay on the cutting edge of technology and procedures and are dedicated to offering results that have proven to be effective. We are hands-on in our approach to hair restoration and focus on developing an individualized plan for people of varying ages, hair types and skin types. We seek to exceed expectations by providing a solution that each patient can be proud of."
        }
    
  return (
    <div className='justify-center banner-bg' style={{background:'url("/assets/images/Untitled-1.jpg")', height:'100vh', backgroundSize:'cover', backgroundAttachment:"fixed"}}>
        <div className='width-93'>
            <Row gutter={[24,24]} align={'middle'} style={{height:'100%'}}>
                <Col lg={{span: 24}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <Space direction='vertical' size={15} className='width-100 text-center' >
                        <Typography.Title level={1} className='m-0 text-white'>
                            {
                                data?.title1
                            }
                        </Typography.Title>
                        <Typography.Title level={3} className='brandcolor m-0 text-center'>{data?.title2}</Typography.Title>
                        <Typography.Title level={4} className='text-white'>
                            {data?.desc}
                        </Typography.Title>
                        <Space>
                            <ErpButton title='Chat with us' to='/'/>
                        </Space>
                    </Space>
                </Col>
                {/* <Col lg={{span: 24}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <div className='imgframe'>
                        <Image src='/assets/images/Untitled-1.jpg' preview={false} />
                    </div>
                </Col> */}
            </Row>
        </div>
    </div>
  )
}

export {MainBanner}