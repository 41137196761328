import {Typography} from "antd"
import { Link } from "react-router-dom"
import './index.css'
const {Title}=Typography
const ErpButton=({title, to, btn, onClick})=>{
    return (
        btn?
        <div className="erp-button py-px-3 w-100 brand-bg" onClick={onClick}>
            <div className="one secondary-bg animation"></div>
            <Title level={5} className='m-0 two text-white'>{title || 'Click Me'}</Title>
        </div>
        :
        <Link to={to || '/'}>
            <div className="erp-button py-px-3 w-100 brand-bg">
                <div className="one secondary-bg animation"></div>
                <Title level={5} className='m-0 two text-white'>{title || 'Click Me'}</Title>
            </div>
        </Link>
    )
}
export {ErpButton}