import React from 'react'
import { AutoMarket, BusinessAnalytics, ContactUs, ImageGallery, MainBanner, OnlineBook, TestimonialSlider, TextSlider } from '../../components'

const HomePage = () => {
  return (
    <div>
      <MainBanner />
      <TextSlider />
      <OnlineBook />      
      <BusinessAnalytics />
      <AutoMarket />
      <TestimonialSlider />
      <ImageGallery />
      <ContactUs />

    </div>
  )
}

export {HomePage}