import React from 'react'
import { Row, Col, Image, Space, Typography, List } from 'antd'

const AutoMarket = () => {

    const data = [
        {
          title: 'Attract and retain clients',
        },
        {
          title: 'Online self-booking',
        },
        {
          title: 'Get trusted ratings and reviews',
        },
        {
          title: 'Ant Design Title 4',
        },
      ];
  return (
    <div className='justify-center mt-5'>
        <div className='width-93'>
            <Row gutter={[24,24]}>
                <Col lg={{span: 12}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                    <Space direction='vertical' size={14}>
                        <Typography.Title level={5} className='text-grey m-0'>
                            AUTOMATED MARKETING
                        </Typography.Title>
                        <Typography.Title level={1} style={{marginTop:'0px'}}>
                            Stay in touch with auto campaigns and messages
                        </Typography.Title>
                        <Typography.Text style={{display:"block"}}>
                            Be seen, be available, build your brand online. Create an online profile on our marketplace to get noticed by thousands of potential clients in your area.
                        </Typography.Text>
                        <Typography.Text style={{display:"block"}}>
                            Be seen, be available, build your brand online. Create an online profile on our marketplace to get noticed by thousands of potential clients in your area.
                        </Typography.Text>
                        <List
                            itemLayout="horizontal"
                            dataSource={data}
                            renderItem={(item, index) => (
                            <List.Item style={{padding:"0px"}}>
                                <Space>
                                    <Image src='/assets/tick.svg' width={'15px'} preview={false} />
                                    <Typography.Text>
                                        {
                                            item?.title
                                        }
                                    </Typography.Text>
                                </Space>
                            </List.Item>
                            )}
                        />
                    </Space>
                </Col>
                <Col lg={{span: 12}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                    <div className='text-center'>
                        <Image src='/assets/images/hair-2.avif' width={'70%'} preview={false} />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {AutoMarket}